import React from 'react'
import {FaGithub, FaLinkedin, FaInstagram} from "react-icons/fa";
import {HiOutlineMail} from "react-icons/hi";
import {BsPersonLinesFill} from "react-icons/bs"

const SocialLinks = () => {

    const links = [
        {
            id: 1,
            child: (
                <>
                    LinkedIn <FaLinkedin size={30}/>
                </>
            ),
            href: 'https://www.linkedin.com/in/surya-sunkari/',
            style: 'rounded-tr-md',
        },
        {
            id: 2,
            child: (
                <>
                    GitHub <FaGithub size={30}/>
                </>
            ),
            href: 'https://github.com/Surya-Sunkari/',
        },
        {
            id: 3,
            child: (
                <>
                    Instagram <FaInstagram size={30}/>
                </>
            ),
            href: 'https://www.instagram.com/surya_sunk/',
        },
        {
            id: 4,
            child: (
                <>
                    Mail <HiOutlineMail size={30}/>
                </>
            ),
            href: 'mailto:suryasunkari@gmail.com',
        },
        {
            id: 5,
            child: (
                <>
                    Resume <BsPersonLinesFill size={30}/>
                </>
            ),
            href: '/resume.pdf',
            style: 'rounded-br-md',
            download: true
        }    
    ]

  return (
    <div className='hidden lg:flex flex-col top-[35%] left-0 fixed'>
        <ul>
            {links.map(({id, child, href, style, download}) => (
                <li key={id} className={'flex justify-between items-center w-40 h-14 px-4 bg-gray-500 ml-[-100px] hover:rounded-md duration-300 hover:ml-[-5px] ' + style}>
                    <a href={href} className='flex justify-between items-center w-full text-white' download={download} target='_blank' rel='noreferrer' >
                        {child}
                    </a>
                </li>
            ))}
        </ul>
    </div>
  )
}

export default SocialLinks